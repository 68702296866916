import React from "react"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"

const ToggleContainer = props => {
  return (
    <FormControlLabel
      control={
        <Switch
          onChange={(e, v) => props.onChange(v)}
          checked={props.value || false}
          color={"primary"}
        />
      }
      label={props.label}
    />
  )
}

ToggleContainer.defaultProps = {}

export default ToggleContainer
